import { TPosition } from 'src/design-system'
import { store } from 'store/index'

export class PositionsService {
  async create(props: {
    disciplineId: number
    frameworkId: number
    name: string
    seniorityLevel: number
  }) {
    const { disciplineId, frameworkId, name, seniorityLevel } = props

    try {
      await store.positions.create({
        framework: `${frameworkId}`,
        seniorityLevel,
        name,
        disciplines: [`${disciplineId}`],
      })
    } catch (e) {
      return 'Something went wrong, please try again.'
    }
  }

  async update(props: {
    position: TPosition
    customLabel: string | undefined
  }) {
    const { position, customLabel } = props
    if (!position.id) return

    try {
      store.positions.update(`${position.id}`, {
        customLabel,
      })
      hydrateProps()
    } catch (e) {
      return 'Something went wrong, please try again.'
    }
  }
}

const hydrateProps = () => {
  const event = new Event('hydrate', { bubbles: true, cancelable: true })
  document.dispatchEvent(event)
}
